import React from 'react';

import type { ExtensionParams } from '@atlaskit/editor-common/extensions';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { RENDERER, getExperienceName } from '@confluence/macro-tracker';

import type { LinkCardsParameters } from '../../link-cards/linkCardsTypes';
import { CarouselContent } from '../CarouselContent/CarouselContent';
import { CustomSitesRenderedExtension } from '../../shared-components/CustomSitesRenderedExtension';

type CarouselContainerProps = {
	extensionNode: ExtensionParams<LinkCardsParameters>;
	contentId: string;
};

export const Carousel = ({ extensionNode, contentId }: CarouselContainerProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { parameters, localId = '' } = extensionNode;
	if (!parameters) {
		return null;
	}

	const experienceName = getExperienceName(RENDERER, extensionNode);

	return (
		<CustomSitesRenderedExtension extensionNode={extensionNode} contentId={contentId}>
			<CarouselContent
				extensionLocalId={localId}
				parameters={parameters}
				isInViewMode
				contentId={contentId}
				experienceName={experienceName}
				createAnalyticsEvent={createAnalyticsEvent}
			/>
		</CustomSitesRenderedExtension>
	);
};
